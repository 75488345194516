import React, { useState, useContext } from "react";
import { CartDispatchContext, addToCart, rmToCart } from "contexts/cart";
let i = 0;

const MissIng = ({ data }) => {  
  const [MissQuantity, setMissQuantity] = useState(0);
  const [isAdded, setIsAdded] = useState(false);
  const dispatch = useContext(CartDispatchContext);
  const { image, name, price, id, stock } = data;

  const handleAddToCart = () => {
    const product = { ...data, quantity: 1 };
    addToCart(dispatch, product);
    setMissQuantity(prevQuantity => prevQuantity + 1);
    setIsAdded(true);
    setTimeout(() => {
      setIsAdded(false);
    }, 3500);
  };
  const handleRmToCart = () => {
    const product = { ...data, quantity: 1 };
    if(MissQuantity > 0){
      rmToCart(dispatch, product);
      setMissQuantity(prevQuantity => prevQuantity - 1);
    }
    setIsAdded(true);
    setTimeout(() => {
      setIsAdded(false);
    }, 3500);
  };

  return (
    <div className="add-rm-item">
        <button onClick={handleRmToCart} >-</button>
         {MissQuantity} 
        <button onClick={handleAddToCart}>+</button>
        <div className="none">{i++}</div>
    </div>
  );
};

export default MissIng;