import React, { useContext, useState, useEffect, useRef } from "react";
import ProductCard from "components/Product";
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd'
import {
  ProductsStateContext,
  ProductsDispatchContext,
  getProducts
} from "contexts/products";
import { CommonStateContext } from "contexts/common";
import "assets/scss/pages/tour.scss";
//import introJs from "intro.js";
//import '../assets/introjs/intro-recepie-js.css'
//body > div
const Home = () => {
  const steps = [
    {
      id: 'intro',
      attachTo: { element: '.container .common-layout', on: 'top' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve();
          }, 500);
        });
      },
      arrow:true,
      buttons: [
        {
          classes: 'shepherd-button-primary-next',
          text: 'Siguiente',
          type: 'next'
        }
      ],
      classes: 'custom-class-name-1 custom-class-name-2',
      highlightClass: 'highlight',
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      title: '¡Bienvenido/a a nuestra aplicación Recepie Gen!',
      text: ['Te guiaremos paso a paso para que conozcas todas las funciones que tiene para ofrecer la aplicación.'],
      when: {
        show: () => {
          console.log('show step');
        },
        hide: () => {
          console.log('hide step');
        }
      }
    },
    {
      id: 'boton',
      attachTo: { element: '#buttonchat', on: 'top' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            setisshowbutton(true);
            resolve();
          }, 500);
        });
      },
      arrow:true,
      buttons: [
        {
          classes: 'shepherd-button-primary-back',
          text: 'Regresar',
          type: 'back'
        },
        {
          classes: 'shepherd-button-primary-next',
          text: 'Siguiente',
          type: 'next'
        }
      ],
      classes: 'custom-class-name-1 custom-class-name-2',
      highlightClass: 'highlight',
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      title: 'Chat de Recepie Gen',
      text: ['Presiona este botón para abrir el chat.'],
      when: {
        show: () => {
          console.log('show step');
        },
        hide: () => {
          console.log('hide step');
        }
      }
    },
    {
      id: 'chat-bot',
      attachTo: { element: '#chat', on: 'left' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve();
            setisshowbutton(false);
            pruvChat();
          }, 500);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button-primary-back',
          text: 'Regresar',
          type: 'back'
        },
        {
          classes: 'shepherd-button-primary-next',
          text: 'Siguiente',
          type: 'next'
        }
      ],
      classes: 'custom-class-name-1 custom-class-name-2',
      highlightClass: 'highlight',
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      title: 'Chat de Recepie Gen',
      text: ['Este es el chatbot.'],
      when: {
        show: () => {
          console.log('show step');
        },
        hide: () => {
          console.log('hide step');
        }
      }
    },
    {
      id: 'mensajes',
      attachTo: { element: '.chat-box-bot', on: 'left' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve();
            setisshowbutton(false);
            pruvChat();
          }, 500);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button-primary-back',
          text: 'Regresar',
          type: 'back'
        },
        {
          classes: 'shepherd-button-primary-next',
          text: 'Siguiente',
          type: 'next'
        }
      ],
      classes: 'custom-class-name-1 custom-class-name-2',
      highlightClass: 'highlight',
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      title: 'Chat de Recepie Gen',
      text: ['Este es un mensaje del bot'],
      when: {
        show: () => {
          console.log('show step');
        },
        hide: () => {
          console.log('hide step');
        }
      }
    },
    {
      id: 'enviar',
      attachTo: { element: '.send-msg', on: 'left' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve();
            setisshowbutton(false);
            pruvChat();
          }, 500);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button-primary-back',
          text: 'Regresar',
          type: 'back'
        },
        {
          classes: 'shepherd-button-primary-next',
          text: 'Siguiente',
          type: 'next'
        }
      ],
      classes: 'custom-class-name-1 custom-class-name-2',
      highlightClass: 'highlight',
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      title: 'Chat de Recepie Gen',
      text: ['Aquí puedes escribir los mensajes para responder lo que indica el bot.'],
      when: {
        show: () => {
          console.log('show step');
        },
        hide: () => {
          console.log('hide step');
        }
      }
    },
    {
      id: 'limpiar-btn',
      attachTo: { element: '#limpiar', on: 'left' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve();
            setisshowbutton(false);
            pruvChat();
          }, 500);
        });
      },
      buttons: [
        {
          classes: 'shepherd-button-primary-back',
          text: 'Regresar',
          type: 'back'
        },
        {
          classes: 'shepherd-button-primary-next',
          text: 'Siguiente',
          type: 'next'
        }
      ],
      classes: 'custom-class-name-1 custom-class-name-2',
      highlightClass: 'highlight',
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      title: 'Chat de Recepie Gen',
      text: ['Con este botón puedes limpiar el chat y comenzar una nueva conversación.'],
      when: {
        show: () => {
          console.log('show step');
        },
        hide: () => {
          console.log('hide step');
        }
      }
    },
    {
      id: 'cerrar_chat',
      attachTo: { element: '#close-chat-btn', on: 'left' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve();
            setisshowbutton(false);
            pruvChat();
          }, 500);
        });
      },

      buttons: [
        {
          classes: 'shepherd-button-primary-back',
          text: 'Regresar',
          type: 'back'
        },
        {
          classes: 'shepherd-button-primary-next',
          text: 'Siguiente',
          type: 'next'
        }
      ],
      classes: 'custom-class-name-1 custom-class-name-2',
      highlightClass: 'highlight',
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      title: 'Chat de Recepie Gen',
      text: ['Si quieres cerrar el chat utiliza este botón.'],
      when: {
        show: () => {
          console.log('show step');
        },
        hide: () => {
          console.log('hide step');
        }
      }
    },
    {
      id: 'final',
      attachTo: { element: '#help', on: 'left' },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve();
            closeChat();
          }, 500);
        });
      },
      
      buttons: [
        {
          classes: 'shepherd-button-primary-back',
          text: 'Regresar',
          type: 'back'
        },
        {
          classes: 'shepherd-button-primary-next',
          text: 'Terminar tour',
          type: 'cancel'
        }
      ],
      classes: 'custom-class-name-1 custom-class-name-2',
      highlightClass: 'highlight',
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      title: 'Chat de Recepie Gen',
      text: ['Ahora estas listo/a para utilizar el chat. Si quieres volver a ver el tour pulsa este botón.'],
      when: {
        show: () => {
          console.log('show step');
        },
        hide: () => {
          console.log('hide step');
        }
      }
    },
    // 
  ];
  const tourOptions = {
    useModalOverlay: true,
    defaultStepOptions: {
      classes: 'shepherd-theme-custom shepherd-theme-arrows',
      cancelIcon: {
        enabled: false
      }
    }
  };
  function Button() {
    const tour = useContext(ShepherdTourContext);
  
    return (
      <button onClick={tour.start}>
      ?
    </button>
    );
  }
  //const intro = introJs();
  /*useEffect(() => {
    
    intro.setOptions({
      nextLabel: 'Siguiente', 
      prevLabel: 'Anterior', 
      skipLabel: 'x', 
      doneLabel: 'Terminar',
      showBullets: false,
      steps: [
        {
          title: '¡Bienvenido/a a nuestra aplicación Recepie Gen!',
          intro: "Te guiaremos paso a paso para que conozcas todas las funciones que tiene para ofrecer la aplicación."
        },
        {
          element: document.getElementById('buttonchat'),
          title: 'Chat de Recepie Gen',
          intro: "Presiona este botón para abrir el chat."
        },
        {
          element: document.getElementById('chat'),
          title: 'Chat de Recepie Gen',
          intro: "Este es el chatbot."
        },
        {
          element: document.getElementById('enviarmsg'),
          title: 'Chat de Recepie Gen',
          intro: "Aquí puedes escribir los mensajes para responder lo que indica el bot."
        },
        {
          element: document.getElementById('limpiar'),
          title: 'Chat de Recepie Gen',
          intro: "Con este boton puedes limpiar el chat para comenzar una nueva conversación."
        },
        {
          element: document.getElementById('close'),
          title: 'Chat de Recepie Gen',
          intro: "Con este boton puedes cerrar el chat."
        }
      ]
    });
    intro.start();
  }, []);*/


  const { products, isLoading, isLoaded } = useContext(ProductsStateContext);
  const { searchKeyword } = useContext(CommonStateContext);
  const dispatch = useContext(ProductsDispatchContext);
  const [isshowbutton, setisshowbutton] =useState(true);

  //Chat->start
  const [new_msg, setnew_msg] =useState("");
  const [print_msg, setprint_msg] =useState([]);
  const axios = require('axios');
  const [hasVerticalOverflow, sethasVerticalOverflow] =useState(false);
  const chatElementRef = useRef(null);
  const [isWritingbot, setisWritingbot] =useState(false);
  const [isCleanChat, setisCleanChat] =useState(false);
  const [isusrWeritten, setisusrWeritten] =useState(true);
  const [isbotWeritten, setisbotWeritten] =useState(true);

  let ychat = 0;

  
  const pruvChat = () =>{
    //print_msg.push({role:"bot", msg:"data"});
    //let timeout1 = setTimeout(setprint_msg([...print_msg, {role:"bot", msg:new_msg}]), 15000);
    setprint_msg([...print_msg, {role:"bot", msg:"Hola, soy el chat bot"}]);
   // scrollToBottom();
  }

  const callChat = (call,clean) =>{
    console.log("Peticion que se hace: Pregunta:",call," Se limpia o no?: ",clean);
    setisWritingbot(true);
    fetch('https://poc-apis-ia.pocsmobiik.com.mx/chat-recipegen', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "question":call,
          "clear_history":clean}
        )
    }) 
      .then(response => {
        if (!response.ok) {
          throw new Error('La solicitud fetch no fue exitosa');
        }
        return response.json();
      })
      .then(data => {
        if (data) {
            console.log(data);
            setprint_msg([...print_msg, {role:"bot", msg:data}]);
            setisWritingbot(false);
            console.log("Se recibió la respuesta del bot")
        } else {
          console.log('No hay datos disponibles');
        }
      })
      .catch(error => {
        console.error('Hubo un problema con la operación fetch:', error);
      });
  }

  const chatElement = document.getElementById('chat'); 
  
  function scrollToBottom() {
    ychat=chatElement.scrollHeight;
    ychat+=100;
    chatElement.scrollTop = ychat;
  }

  const msgChat = (event) => {
    if (event) {
      event.preventDefault();
    }
  }

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setnew_msg(inputValue);
    if(chatElement){
      if (chatElement.scrollHeight > chatElement.clientHeight){
        sethasVerticalOverflow(true);
      }
    }
  };
  const handleSubmit = (event) => {
    
    setprint_msg([...print_msg, {role:"usr", msg:new_msg}]);
    setisusrWeritten(false);
    if(chatElement){
      if (chatElement.scrollHeight > chatElement.clientHeight){
        sethasVerticalOverflow(true);
      }
    }
    //print_msg.push({role:"usr", msg:new_msg});
    event.preventDefault();
    console.log(print_msg);
    setnew_msg("");
  };
  const handleClear = () => {
    setprint_msg([]);
    setisCleanChat(true);
    sethasVerticalOverflow(false);
  };
  useEffect(() => {
      if(chatElement){scrollToBottom()}
      sethasVerticalOverflow(false);
      if(!isusrWeritten){
        //pruvChat();
        console.log(print_msg[print_msg.length-1].msg);
        callChat(print_msg[print_msg.length-1].msg,false);
        setisusrWeritten(true);
      }
      if(isCleanChat){
        //pruvChat();
        callChat("Hola",true);
        setisCleanChat(false);
      }
  }, [print_msg]);
  useEffect(() => {
    // Establecer el valor inicial del scroll
    if (chatElementRef.current) {
      chatElementRef.current.scrollTop = 200; // Valor deseado del scroll en píxeles
    }
  }, []);
  
  //Chat->end
  
  const showbutton = () =>{
    setisshowbutton(false);
    callChat("Hola",true);
    //pruvChat();
  }
  const closeChat = () =>{
    setisshowbutton(true);
    setprint_msg([]);
  }
  const productsList =
    products &&
    products.filter((product) => {
      return (
        product.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        !searchKeyword
      );
    });

  useEffect(() => {
    getProducts(dispatch);
  }, []);


  if (isLoading) {
    return (
      <div className="products-wrapper">
        <h1>Loading...</h1>
      </div>
    );
  }
  

  return (
    <ShepherdTour steps={steps} tourOptions={tourOptions}>
      <div className="principal-home">
      <div id="buttonchat" className="conteiner" style={!isshowbutton ? {display:"none"}:null}>
          <button  onClick={showbutton}>
          <img  src="https://res.cloudinary.com/dyz6zbdoc/image/upload/v1685034099/recepies/Chatbot/button-chatbot_jjsxqq.png"></img>
            <p>Shop<br></br><span>Smart</span></p>
          </button>
      </div>
      
      <div id="help" className="help">
          <Button/>
      </div>
      <div className="tienda">
      
        <div className="products-wrapper">
            <div className="products">
              {isLoaded &&
                productsList.map((data) => {
                  return <ProductCard key={data.id} data={data} />;
                })}
            </div>
        </div>
      </div>
      <div className="chatbot" style={isshowbutton ? {display:"none"}:null}>
        <div className="chat" id="chat">
            <div className="headder-chat">
              <img src="https://res.cloudinary.com/dyz6zbdoc/image/upload/v1685034099/recepies/Chatbot/button-chatbot_jjsxqq.png"></img>
              <p>Shop<span>Smart</span></p> 
              <button id="close-chat-btn" className="cerrarchat" onClick={closeChat} >  X  </button>
            </div>
            <div  className="limpiar-chat">
              <button id="limpiar" onClick={handleClear}>Limpiar</button>
            </div>
            <div className="show-msg" ref={chatElementRef}>
                  { 
                    print_msg.map ((mensaje) => {
                      return (
                        <div key={"chat"} className={mensaje.role=="usr" ? "chat-box-usr":"chat-box-bot"}>{mensaje.role=="bot" && <img src="https://res.cloudinary.com/dyz6zbdoc/image/upload/v1685034105/recepies/Chatbot/icon-chatbot_xhlosn.png"></img>}{mensaje.msg}</div>
                      );
                    })
                  }
                  {isWritingbot && 
                    <div className="typing-animation">
                      <span className="dot first"></span>
                      <span className="dot second"></span>
                      <span className="dot third"></span>
                    </div>
                  }
            </div>
            <div className="enviarmsg">
                  <form onSubmit={handleSubmit} style={{ position: print_msg.length<3 ? "absolute" : "fixed" }} className="send-msg">
                    <input disabled={isWritingbot} value={new_msg} onChange={handleInputChange} type="text" id="myInput"/>
                    <button type="submit" id="send-button">
                      <img src="https://res.cloudinary.com/dyz6zbdoc/image/upload/v1685034109/recepies/Chatbot/srend-msg-chatbot_ksaiwg.png"></img>
                    </button>
                  </form>
            </div>
          
        </div>
      </div>
      
    </div>
    </ShepherdTour>
    
      
  );
};

const Chat = (clean) => {

  

  return(
    <div>
      
    </div>
  );
  
};
export default Home;
