import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
        <img className="logo" src='https://res.cloudinary.com/dyz6zbdoc/image/upload/v1684291594/Mobiikicon_yxlnwn.png'
              alt="Mobiik"
            />
    </footer>
  );
};

export default Footer;
